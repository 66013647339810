<template>
    <div class="flexPage darkPage">
        <div class="flexHead">
            <navbar :params="pageInfo" />
        </div>
        <div class="mt3">
            <van-cell @click="$mts.goto('setLang')" :title="$t('assets.personal.changeLang')" is-link>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#0D1421" />
                </template>
            </van-cell>
            <!-- <van-cell :title="$t('assets.personal.topic_switching')" is-link>
                <template #right-icon>
                    <van-switch v-model="$store.state.app.nightModel" size="24px" active-color="#333" inactive-color="#fff"
                        @change="onChangeTheme" />
                </template>
            </van-cell> -->
            <!-- <van-cell @click="logoutVisible = true" :title="$t('assets.personal.write_off')" is-link>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#0D1421" />
                </template>
            </van-cell> -->
        </div>

        <!-- <van-dialog v-model="logoutVisible" title="" show-cancel-button :confirmButtonText="$t('button.otc.sure')"
            :cancelButtonText="$t('button.otc.cancel')" @confirm="closeAccount" @cancel="logoutVisible = false">
            <div class="contentBox">是否注销？</div>
        </van-dialog> -->
    </div>
</template>

<script>
import navbar from "@/views/modules/navbar.vue";

export default {
    data() {
        return {
            pageInfo: {
                title: '',
                customTitle: this.$t('assets.personal.setting'),
                navbarStyle: 'normal',
                backname: "my"
            },
            logoutVisible: false
        }
    },
    components: { navbar },
    methods: {
        onChangeTheme(bool) {
            this.$store.state.app.nightModel = bool
            localStorage.setItem('nightModel', bool)
        },
        // 注销账号
        async closeAccount() {
            try {
                let result = await this.$http.post(this.host + '/uc/closeAccount');
                if (result.data.code == 0) {
                    this.logoutVisible = false;
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    this.$toast('注销成功');
                    this.$router.push({ name: 'home' });
                }

            } catch (error) {
                this.$toast('注销失败');
            }
        }
    }
}
</script>

<style>
.contentBox {
    height: 20.3562vw;
}
</style>